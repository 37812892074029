exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-questions-qna-js-js": () => import("./../../../src/pages/questions/qna.js.js" /* webpackChunkName: "component---src-pages-questions-qna-js-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-toolkit-discussion-warehouse-js": () => import("./../../../src/pages/toolkit/discussion-warehouse.js" /* webpackChunkName: "component---src-pages-toolkit-discussion-warehouse-js" */),
  "component---src-pages-toolkit-import-slack-threads-js": () => import("./../../../src/pages/toolkit/import-slack-threads.js" /* webpackChunkName: "component---src-pages-toolkit-import-slack-threads-js" */),
  "component---src-pages-toolkit-qna-js-js": () => import("./../../../src/pages/toolkit/qna.js.js" /* webpackChunkName: "component---src-pages-toolkit-qna-js-js" */)
}

